import Vue from "vue";
import store from "./index"
const getDefaultState = () => {
    return {
        tailwindLicense:false,
        lessons: [],
        activeTabName:'addition',
        selectedColor:'white',
        selectedTheme:'default',
        selectedComponent:'heroSections',

    }
}

let state = getDefaultState();
const actions = {

    getLessons({commit}, lessons) {
        let uri = 'lessons'
        if (store.getters.userFromDB){
            uri += '?user_id='+store.getters.userFromDB.id
        }
        Vue.prototype.$vumonAxios("get", uri)
            .then(response => {

                commit('setLessons',  response.data.data);
            })
            .catch(error => {
                console.log(error);
            });

    },
    setActiveTabName({commit}, activeTabName) {
        commit('setActiveTabName',  activeTabName);
    },
    selectedColor({commit}, selectedColor) {
        commit('selectedColor',  selectedColor);
    },
    selectedTheme({commit}, selectedTheme) {
        commit('selectedTheme',  selectedTheme);
    },
    selectedComponent({commit}, selectedComponent) {
        commit('selectedComponent',  selectedComponent);
    },
    tailwindLicense({commit}, tailwindLicense) {
        commit('tailwindLicense',  tailwindLicense);
    },
}

const mutations = {
    setLessons(state, lessons) {
        lessons = lessons.map(lesson =>{
            lesson.engine_props = JSON.parse(lesson.engine_props)
            return lesson
        })
        state.lessons = lessons;
    },
    setActiveTabName(state,activeTabName) {
        state.activeTabName = activeTabName;
    },
    selectedColor(state,selectedColor) {
        state.selectedColor = selectedColor;
    },
    selectedTheme(state,selectedTheme) {
        state.selectedTheme = selectedTheme;
    },
    selectedComponent(state,selectedComponent) {
        state.selectedComponent = selectedComponent;
    },
    tailwindLicense(state,tailwindLicense) {
        state.tailwindLicense = tailwindLicense;
    },
    logoutLessons(state) {
        state.lessons = [];
        state.activeTabName ='addition';

    }
}

const getters = {
    lessons: state => state.lessons,
    tailwindLicense: state => state.tailwindLicense,
    selectedColor: state => state.selectedColor,
    selectedTheme: state => state.selectedTheme,
    selectedComponent: state => state.selectedComponent,
    activeTabName: state => state.activeTabName,
}
export default {
    state,
    actions,
    mutations,
    getters
}
