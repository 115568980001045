const getDefaultState = () => {
    return {
        numberThatTheUserWrote: null,
        activeExerciseId: null,
        digitsNeededForWritingArea:1
    }
}

let state = getDefaultState();
const actions = {
    setNumberThatTheUserWrote({commit}, numberThatTheUserWrote) {
        commit('setNumberThatTheUserWrote', numberThatTheUserWrote);
    },
    setActiveExerciseId({commit}, activeExerciseId) {
        commit('setActiveExerciseId', activeExerciseId);
    },
    setDigitsNeededForWritingArea({commit}, digitsNeededForWritingArea) {
        commit('setDigitsNeededForWritingArea', digitsNeededForWritingArea);
    }
}

const mutations = {
    setNumberThatTheUserWrote(state, numberThatTheUserWrote) {
        state.numberThatTheUserWrote = numberThatTheUserWrote;
    },
    setActiveExerciseId(state, activeExerciseId) {
        state.activeExerciseId = activeExerciseId;
    },
    setDigitsNeededForWritingArea(state, digitsNeededForWritingArea) {
        state.digitsNeededForWritingArea = digitsNeededForWritingArea;
    },
    logoutUserInput(state) {
        state.numberThatTheUserWrote = null;
        state.activeExerciseId = null;
        state.digitsNeededForWritingArea = 1;
    }
}

const getters = {
    numberThatTheUserWrote: state => state.numberThatTheUserWrote,
    activeExerciseId: state => state.activeExerciseId,
    digitsNeededForWritingArea: state => state.digitsNeededForWritingArea,
}
export default {
    state,
    actions,
    mutations,
    getters
}
