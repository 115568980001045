<template>
  <div class="one-template-item container">
    <div v-if="!preview">
      <div class="">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <div class="nav-link" :class="{'active': activeTab === 'tab1'}" @click="activeTab = 'tab1'" href=""
                 role="tab">HTML
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-link" :class="{'active': activeTab === 'tab2'}" @click="activeTab = 'tab2'" href=""
                 role="tab">Code
            </div>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane" :class="{'active': activeTab === 'tab1'}" id="tab1">
            <!-- content for tab 1 goes here -->
            <div v-html="templateItem.contents"></div>

          </div>
          <div role="tabpanel" class="tab-pane" :class="{'active': activeTab === 'tab2'}" id="tab2">
            <!-- content for tab 2 goes here -->
            <code-viewer :code-of-file="templateItem.contents"></code-viewer>
          </div>
        </div>
      </div>
      <div class="one-template-item__title"></div>
      <div class="one-template-item__content">
        <!-- horizontal divider -->
        <hr>
      </div>
    </div>
    <div v-else>
      <div v-html="templateItem.contents"></div>
    </div>


  </div>
</template>

<script>
import CodeViewer from "./CodeViewer.vue";

export default {
  name: 'one-template-item',
  components: {CodeViewer},
  props: ['templateItem','preview'],
  mixins: [],
  data() {
    return {
      activeTab: 'tab1',
      iframeUniqueID: "iframe" + Math.random().toString(36).substr(2, 9),
    }
  },
  mounted() {
    let html = this.templateItem.contents

    this.iframe = document.getElementById(this.iframeUniqueID);
    //get contents from HeroSections folder and insert it into the iframe
    if (!this.iframe) {
      return
    }
    this.iframe.srcdoc = "<html><head>"
        + '<script src="https://cdn.tailwindcss.com"><\/script>'
        + "<\/head><body>" + html
        + "</body></html>";
  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss">
.one-template-item {
  width: 80vw;



  /* optional custom styling for tabs */
  iframe {
    width: 100%;
    height: 100vh;
  }

  .nav-tabs {
    margin-bottom: 15px;
    width: 70vw;

  }

  &__title {

  }

  &__content {
    padding-top: 3vh;
    padding-bottom: 5vh;
    margin-left: 5vw;
    margin-right: 5vw;

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .one-template-item {
    &__title {

    }

    &__content {

    }
  }
}

</style>

