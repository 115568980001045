<template>
  <div class="template-viewer">
    <h1>Select a Component, and theme and a base color...</h1>
    <div class="template-viewer__title">
      <component-selector/>
      <theme-selector/>
      <color-selector/>
    </div>
    <br>
    <h2>Scroll down to see the templates for {{selectedColor}} colored,{{selectedTheme}} themed {{selectedComponent}} </h2>

    <div class="template-viewer__content">
      <themeViewer :theme-object="defaultTheme"/>
    </div>
  </div>
</template>

<script>
import FilesJSON from './GeneratedTemplates/files.json'
import ThemeViewer from "@/views/ThemeViewer.vue";
import ColorSelector from "@/components/Templates/ColorSelector.vue";
import ThemeSelector from "@/components/Templates/ThemeSelector.vue";
import ComponentSelector from "@/components/Templates/ComponentSelector.vue";

export default {
  name: 'template-viewer',
  components: {ComponentSelector, ThemeSelector, ColorSelector, ThemeViewer},
  props: [],
  mixins: [],
  data() {
    return {

    }
  },
  mounted() {


  },
  methods: {
    getFilesOfCategory(category) {
      return FilesJSON.filter(file => file.folder === category)
    }
  },
  computed: {
    defaultTheme () {
      return FilesJSON[this.selectedTheme]
    },
    selectedTheme: {
      get() {
        return this.$store.getters.selectedTheme
      },
      set(value) {
        this.$store.dispatch('selectedTheme', value)
      }
    },
    selectedColor: {
      get() {
        return this.$store.getters.selectedColor
      },
      set(value) {
        this.$store.dispatch('selectedColor', value)
      }
    },
    selectedComponent: {
      get() {
        return this.$store.getters.selectedComponent
      },
      set(value) {
        this.$store.dispatch('selectedComponent', value)
      }
    }
  }
}
</script>
<style lang="scss">
.template-viewer {

  &__title {
    display: flex;
    justify-content: space-evenly;

  }

  &__content {



  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .template-viewer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

