<template>
  <div class="theme-selector">

    <div class="theme-selector__content">
      <theme-selector-tool v-model="selectedTheme"></theme-selector-tool>
    </div>
  </div>
</template>

<script>
import ThemeSelectorTool from "@/components/Templates/ThemeSelectorTool.vue";

export default {
  name: 'theme-selector',
  components: {ThemeSelectorTool},
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    selectedTheme: {
      get() {
        return this.$store.getters.selectedTheme
      },
      set(value) {
        this.$store.dispatch('selectedTheme', value)
      }
    }
  }
}
</script>
<style lang="scss">
.theme-selector {

  &__title {


  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .theme-selector {
    &__title {

    }

    &__content {

    }
  }
}

</style>

