<template>
  <div class="component-selector">

    <div class="component-selector__content">
      <component-selector-tool v-model="selectedComponent"></component-selector-tool>
    </div>
  </div>
</template>

<script>
import ComponentSelectorTool from "@/components/Templates/ComponentSelectorTool.vue";

export default {
  name: 'component-selector',
  components: {ComponentSelectorTool},
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    selectedComponent: {
      get() {
        return this.$store.getters.selectedComponent
      },
      set(value) {
        this.$store.dispatch('selectedComponent', value)
      }
    }
  }
}
</script>
<style lang="scss">
.component-selector {

  &__title {


  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .component-selector {
    &__title {

    }

    &__content {

    }
  }
}

</style>

