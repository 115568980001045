<template>
  <div class="app-footer">
    <div class="app-footer__title"></div>
    <div class="app-footer__content">

      <div class="flex flex-wrap mt-4">
        <div class="w-full px-2 md:w-1/2 lg:w-3/4  md:mb-0 text-sm text-gray-600">© 2023 Fresh Inspo. All rights reserved.</div>
        <div class="w-full px-2 md:w-1/2 lg:w-1/4  md:mb-0 text-sm text-gray-600">Made with ❤️ by Renideas. And yes, all the website has been built using this website</div>
        <a  class="w-full px-2 md:w-1/2 lg:w-1/4  md:mb-0 text-sm text-gray-600" href="/terms">Terms and conditions</a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-footer',
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss">
.app-footer {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .app-footer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

