import Vue from "vue";
import store from "./index"
const getDefaultState = () => {
    return {
        lessons: [],
        userFromDB:null

    }
}

let state = getDefaultState();
const actions = {

    setUserFromDB({commit}, userFromDB) {
        commit('setUserFromDB',  userFromDB);
    },
    getUserFromDB({commit},email){
        if (store.getters.userFromDB){
            return
        }
        if (!email){
            return
        }
        return Vue.prototype.$vumonAxios('get','user?email='+email).then(response =>{
            commit('setUserFromDB',response.data.data)
        })
    },

    logout({commit}){
        commit('logoutUserFromDB')
        commit('logoutLoading')
        commit('logoutUserInput')
    }

}

const mutations = {
    setUserFromDB(state, userFromDB) {
        state.userFromDB = userFromDB;
    },
    logoutUserFromDB(state) {
        state.userFromDB = null;
    }
}

const getters = {
    userFromDB: state => state.userFromDB,
}
export default {
    state,
    actions,
    mutations,
    getters
}
