<template>
  <div class="logged-in-daily-workout">
    <div class="logged-in-daily-workout__title"></div>
    <div class="logged-in-daily-workout__content" >
      <div class="daily-workout__login" v-if="!$auth.isAuthenticated">
        <span>You are not logged in...</span>
        <span><login-button></login-button></span>

      </div>
    </div>
  </div>
</template>

<script>

import LoginButton from "@/components/Navigation/LoginButton";
export default {
  name: 'logged-in-daily-workout',
  components: {LoginButton},
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

    if (this.$auth.isAuthenticated){
      this.getData()
    }
  },
  methods: {
    getData(){
      console.log('logged')

    }
  },
  computed: {
    userFromDB(){
      return this.$store.getters.userFromDB
    }
  },
  watch:{
    '$auth.user.email':{
      handler:function (newVal){

        if (newVal){
          this.getData()
        }
      },
      deep:true
    }
  }
}
</script>
<style lang="scss">
.logged-in-daily-workout {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .logged-in-daily-workout {
    &__title {

    }

    &__content {

    }
  }
}

</style>

