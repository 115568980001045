import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {authGuard} from "@/auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },


  // NEW - add the route to the /profile component
  {
    path: '/activate_tailwind_license',
    name: 'ActivateLicenseView',
    component: () => import(/* webpackChunkName: "ActivateLicenseView" */ '../views/ActivateLicenseView.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "ProfileView" */ '../views/Settings.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/landing_builder',
    name: 'landingbuilder',
    component: () => import(/* webpackChunkName: "ProfileView" */ '../views/LandingBuilderView.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/TermsView.vue'),
    //beforeEnter: authGuard
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
