<template>
  <div>
    <nav class="bg-gray-50 border-gray-200 dark:bg-gray-900">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
        <a href="/" class="flex items-center">
          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Fresh Inspo</span>
        </a>
        <div class="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul class="font-medium flex flex-col   mt-4  rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li v-if="tailwindLicense">
              <router-link  class="block py-2 pl-3 pr-4  bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 text-gray-400 dark:text-white md:dark:text-blue-500" aria-current="page" to="/">Lifetime access granted</router-link>
            </li>
            <li >
              <router-link  class="block py-2 pl-3 pr-4 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 text-gray-400 dark:text-white md:dark:text-blue-500" aria-current="page" to="/landing_builder">Landing Builder</router-link>
            </li>
            <li v-if="!tailwindLicense">
              <router-link  class="block py-2 pl-3 pr-4 bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page" to="/activate_tailwind_license">Activate License</router-link>
            </li>
            <li v-if="!tailwindLicense">
              <a href="https://renideas.gumroad.com/l/templates" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                Grab License on Gumroad
              </a>
            </li>
            <li>
              <div class="block pb-2  pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                <login-button></login-button>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import LoginButton from "@/components/Navigation/LoginButton.vue";
import HasTailwindLicense from "@/components/Mixins/HasTailwindLicense";

export default {
  name: 'fresh-nav-bar',
  components: {LoginButton},
  mixins: [HasTailwindLicense],
  computed:{

  }
}
</script>
<style lang="scss">
@import "~normalize.css/normalize.css";

body {
  min-height: 90vh;
  padding-bottom: 8vh;
}
</style>
