<template>
  <div class="color-viewer">
    <div class="color-viewer__title"></div>
    <div class="color-viewer__content">
      <category-viewer :component-name="selectedComponent" :files-j-s-o-n="colorObject[selectedComponent]"></category-viewer>
    </div>
  </div>
</template>

<script>
import CategoryViewer from "@/components/Templates/CategoryViewer.vue";

export default {
  name: 'color-viewer',
  components: {CategoryViewer},
  props: ['colorObject'],
  mixins: [],
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {},
  computed: {
    selectedComponent:{
      get(){
        return this.$store.getters.selectedComponent
      },
      set(value){
        this.$store.dispatch('selectedComponent', value)
      }
    }
  }
}
</script>
<style lang="scss">
.color-viewer {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .color-viewer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

