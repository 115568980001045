<template>
  <div class="code-viewer">
    <div class="code-viewer__title"></div>
    <div class="code-viewer__content">

      <prism-editor
          class="my-editor height-200"
          v-model="prettyPrintedHtml"
          :highlight="highlighter"
          line-numbers
          :language="'html'"></prism-editor>

    </div>
  </div>
</template>

<script>


// import highlighting library (you can use any library you want just return html string)
import {highlight, languages} from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markdown';

//import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
export default {
  name: 'code-viewer',
  props: ['codeOfFile'],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    highlighter(code) {
      return highlight(code, languages['html']); // languages.<insert language> to return html with markup
    },
    prettyPrintHtml(htmlString) {
      var tempElement = document.createElement('div');
      tempElement.innerHTML = htmlString.trim();
      var result = '';
      for (var i = 0; i < tempElement.childNodes.length; i++) {
        result += tempElement.childNodes[i].outerHTML;
      }
      return result;
    }
  },
  computed: {
    prettyPrintedHtml(){
      return this.prettyPrintHtml(this.codeOfFile)
    }
  },

}
</script>
<style lang="scss">
.code-viewer {

  &__title {

  }

  &__content {
    .height-200 {
      height: 100%;
      width: 80vw;
    }

    .my-editor {
      /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
      height: 60vh;
      border: 1px solid gray;

      /* you must provide font-family font-size line-height. Example:*/
      font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
      font-size: 14px;
      line-height: 1.5;
      padding: 5px;
    }

    /* optional class for removing the outline */
    .prism-editor__textarea:focus {
      outline: none;
    }

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .code-viewer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

