<template>
  <div class="theme-viewer">
    <div class="theme-viewer__title"></div>
    <div class="theme-viewer__content">
      <color-viewer :colorObject="themeObject[selectedColor]"/>
    </div>
  </div>
</template>

<script>
import ColorViewer from "@/components/Templates/ColorViewer.vue";

export default {
  name: 'theme-viewer',
  components: {ColorViewer},
  props: ['themeObject'],
  mixins: [],
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {},
  computed: {
    selectedColor:{
      get(){
        return this.$store.getters.selectedColor
      },
      set(value){
        this.$store.dispatch('selectedColor', value)
      }
    }
  }
}
</script>
<style lang="scss">
.theme-viewer {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .theme-viewer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

