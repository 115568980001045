<template>
  <div class="component-selector-tool">

    <div class="component-selector-tool__content">
      <label for="components" class="block mb-2 text-sm font-medium text-gray-900 ">Select a component</label>
      <select  id="components" v-model="componentAux" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option>heroSections</option>
        <option>navbars</option>
        <option>headerSections</option>
        <option>footersSections</option>
        <option>newsletterSections</option>
        <option>pricingSections</option>
        <option>404Sections</option>
        <option>featureSections</option>
        <option>testimonialsSections</option>
        <option>statsSections</option>
        <option>loginPage</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'component-selector-tool',
  props: ['value'],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    componentAux: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

  }
}
</script>
<style lang="scss">
.component-selector-tool {

  &__title {


  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .component-selector-tool {
    &__title {

    }

    &__content {

    }
  }
}

</style>

