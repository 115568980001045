<template>
  <div class="home-view-features">
    <div class="home-view-features__title"></div>
    <div class="home-view-features__content">
      <section class="bg-white py-16">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="lg:text-center">
            <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">Features</h2>
            <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              A faster way to build landing pages
            </p>
            <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              This section you are reading has been built using the landing page builder. You can use it to build your own landing pages, or use it as a template for your own website.
            </p>
            <p class="text-xl md:text-2xl xl:text-3xl text-gray-700 text-center mb-10 max-w-2xl"></p>
            <a href="/landing_builder" class="bg-blue-500 hover:bg-blue-700 text-white py-3 px-6 rounded-full text-lg font-semibold">Go to Landing Builder</a>
          </div>

          <div class="mt-10">
            <div class="mt-5 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
              <div class="rounded-lg shadow-md bg-white overflow-hidden">
                <div class="px-5 py-6">
                  <div class="text-center">
                    <span class="text-4xl font-semibold text-gray-900">01</span>
                  </div>
                  <h3 class="mt-2 text-center text-lg leading-6 font-medium text-gray-900">
                    Landing Builder
                  </h3>
                  <p class="mt-3 text-center text-gray-500">
                    Still reading this? Go to the landing page builder and start building your own landing page.
                  </p>
                </div>
              </div>

              <div class="rounded-lg shadow-md bg-white overflow-hidden">
                <div class="px-5 py-6">
                  <div class="text-center">
                    <span class="text-4xl font-semibold text-gray-900">02</span>
                  </div>
                  <h3 class="mt-2 text-center text-lg leading-6 font-medium text-gray-900">
                    Component Library
                  </h3>
                  <p class="mt-3 text-center text-gray-500">
                    Scroll down to browse the component library. Select a component, a theme and a base color... and browser the options.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-view-features',
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss">
.home-view-features {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .home-view-features {
    &__title {

    }

    &__content {

    }
  }
}

</style>

