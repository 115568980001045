// @ts-nocheck
<template>
  <div class="container">
    <br>
    <home-view-features/>
    <template-viewer v-if="tailwindLicense" ></template-viewer>
    <category-viewer v-if="!tailwindLicense" :component-name="'We have a full library but here is a sample Hero Section with Default Theme and white colors'" :files-j-s-o-n="defaultFilesJSON"></category-viewer>
    <category-viewer v-if="!tailwindLicense" :component-name="'Here is another sample Hero section, material theme, blue colors'" :files-j-s-o-n="materialBlueFilesJSON"></category-viewer>



  </div>
</template>

<script>
import Vue from 'vue';


import LoggedInDailyWorkout from "@/views/LoggedInDailyWorkout";
import TemplateViewer from "@/components/Templates/TemplateViewer.vue";
import HasTailwindLicense from "@/components/Mixins/HasTailwindLicense";
import ColorSelector from "@/components/Templates/ColorSelector.vue";
import ThemeViewer from "@/views/ThemeViewer.vue";
import FilesJSON from "@/components/Templates/GeneratedTemplates/files.json";
import CategoryViewer from "@/components/Templates/CategoryViewer.vue";
import ThemeSelector from "@/components/Templates/ThemeSelector.vue";
import HomeViewFeatures from "@/views/HomeViewFeatures.vue";

export default Vue.extend({
  name: 'HomeView',
  components: {
    HomeViewFeatures,
    ThemeSelector,
    CategoryViewer,
    ThemeViewer,
    ColorSelector,
    TemplateViewer,
    LoggedInDailyWorkout,
  },
  mixins:[HasTailwindLicense],
  mounted(){


    setTimeout(()=>{
      if (!this.$auth.isAuthenticated){
        return
      }

    },2000)


  },
  computed:{
    defaultFilesJSON () {
      return FilesJSON.default.white.heroSections
    },
    materialBlueFilesJSON () {
      return FilesJSON.material.blue.heroSections
    }
  },
  methods:{

  }
});
</script>
<style lang="scss">
.home-view{
  &__content{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__item{
      margin: 5px;
    }
  }
}

</style>
