export default {
    computed: {
        tailwindLicense: {
            get() {
                return this.$store.getters.tailwindLicense
            },
            set(value) {
                this.$store.dispatch('tailwindLicense', value)
            }
        }
    }
}
