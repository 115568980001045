<template>
  <div class="login-button">
    <div class="login-button__title"></div>
    <div class="login-button__content">
      <div v-if="!$auth.loading">
        <!-- show login when not authenticated -->
        <button class="bg-transparent hover:bg-blue-500 text-gray-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" v-if="!$auth.isAuthenticated" @click="login">Log in</button>
        <!-- show logout when authenticated -->
        <button class="bg-transparent hover:bg-blue-500 text-gray-400 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded" v-if="$auth.isAuthenticated" @click="logout">Log out</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login-button',
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {
    if (!this.$auth.isAuthenticated){
      this.$store.dispatch('logout')
    }

  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$store.dispatch('logout').then(()=>{
        this.$auth.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      })

    }
  },
  computed: {}
}
</script>
<style lang="scss">
.login-button {
  color:gray;


  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .login-button {
    &__title {

    }

    &__content {

    }
  }
}

</style>

