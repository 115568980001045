<template>
  <div class="color-selector-tool">
    <div class="color-selector-tool__title"></div>
    <div class="color-selector-tool__content">
      <label for="colors" class="block mb-2 text-sm font-medium text-gray-900 ">Select a color</label>
      <select  id="colors" v-model="colorAux" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option disabled value="">Please Select Color</option>
        <option>white</option>
        <option>dark</option>
        <option>blue</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'color-selector-tool',
  props: ['value'],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    colorAux: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>
<style lang="scss">
.color-selector-tool {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .color-selector-tool {
    &__title {

    }

    &__content {

    }
  }
}

</style>

