
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// Import the Auth0 configuration
import { domain, clientId } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';
import estimtrackLoading from './plugins/Loading'
import TextHelper from './plugins/TextHelper'
import Rights from './plugins/Rights'
import vumonAxios from './plugins/VumonAxios'
import { PrismEditor } from 'vue-prism-editor';

import DisableDevtool from 'disable-devtool';
//only in production

if (process.env.NODE_ENV === 'production') {
  DisableDevtool({
    ondevtoolopen(type, next){
      alert('It\'s only 9$, and gives you access for like forever. Buy the license to remove this message. Thanks for your support!');
      window.open("https://fresh-inspo.com");

      location.reload()

    }
  });
}



Vue.use(estimtrackLoading)
Vue.use(TextHelper)
Vue.use(Rights)
Vue.use(vumonAxios)

import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles
Vue.component('PrismEditor', PrismEditor);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
