<template>
  <div class="category-viewer container">

    <h1 class="h1 category-viewer__title" v-if="componentName">{{componentName}}</h1>
    <div class="category-viewer__content">
      <one-template-item v-for="fileJSON in componentsJSON" :preview="preview" :key="fileJSON.content" :templateItem="fileJSON"/>
    </div>
  </div>
</template>

<script>
import OneTemplateItem from "./OneTemplateItem.vue";

export default {
  name: 'category-viewer',
  components: {OneTemplateItem},
  props: ['componentName', 'filesJSON','preview','version'],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    componentsJSON(){
      //check version is a number
      if (typeof this.version === 'number'){
        return [this.filesJSON[this.version]]
      }
      return this.filesJSON




    }
  }
}
</script>
<style lang="scss">
.category-viewer {

  &__title {

  }

  &__content {





  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .category-viewer {
    &__title {

    }

    &__content {

    }
  }
}

</style>

