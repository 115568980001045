<template>
  <div id="app">
    <fresh-nav-bar/>

    <router-view/>

    <app-footer/>
  </div>
</template>
<script>
import FreshNavBar from "@/FreshNavBar.vue";
import AppFooter from "@/views/AppFooter.vue";

export default {
  name: 'app',
  components: {AppFooter, FreshNavBar},
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {


  },
  methods: {
    getRandomInt(min,max){
      return getRandomInt(min,max)
    },

  },
  computed: {}
}
</script>
<style lang="scss">
@import "~normalize.css/normalize.css";

body {
  min-height: 90vh;
  padding-bottom: 8vh;
}
</style>



